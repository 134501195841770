<template>
  <div class="user-info">
    <img :src="icon" alt="head">
    <div class="user-name">
      <span>{{ nickName }}</span>
      <ul>
        <li @click="userInfo()"><i class="icomoon-profile"></i>个人信息</li>
        <!-- <li @click="isChangePassword = true"><i class="icomoon-key"></i>修改密码</li> -->
        <li @click="logout()"><i class="icomoon-switch"></i>退出平台</li>
      </ul>
    </div>
    <teleport to=".base">
      <window title="修改密码" class="dark" windowId="change-password" v-show="isChangePassword"
        @windowHide="passwordClose()">
        <template #body>
          <el-form ref="form" class="line pd-20" :model="password" :rules="rules">
            <div class="insert-h">
              <div class="attr">原始密码</div>
              <div class="val">
                <el-form-item prop="old" class="line">
                  <el-input type="password" v-model="password.old" placeholder="请输入原始密码"></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="insert-h">
              <div class="attr">新密码</div>
              <div class="val">
                <el-form-item prop="new" class="line">
                  <el-input type="password" v-model="password.new" placeholder="新密码由6位以上字符组成"></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="insert-h">
              <div class="attr">重复密码</div>
              <div class="val">
                <el-form-item prop="repeat" class="line">
                  <el-input type="password" v-model="password.repeat" placeholder="重复密码必须与新密码一致"></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </template>
        <template #footer>
          <div class="line pd-20 align-right">
            <el-button @click="passwordClose()">取消</el-button>
            <el-button type="primary" @click="changePassword()">确定</el-button>
          </div>
        </template>
      </window>
    </teleport>
  </div>
</template>
<script>
import Window from "components/common/forms/Window";
export default {
  name: 'User',
  components: {
    Window
  },
  data () {
    return {
      rules: {
        old: [{ required: true, message: '原始密码不能为空', trigger: 'blur' }],
        new: [{ required: true, message: '新密码不能为空', trigger: 'blur' }],
        repeat: [{ required: true, message: '重复输入密码不能为空', trigger: 'blur' }],

      },
      defaultHead: require("assets/images/frame/default-head.svg"),
      isChangePassword: false,
      password: {
        old: "",
        new: "",
        repeat: "",
      },
    }
  },
  computed: {
    nickName: function () {
      return this.$store.state.user ? this.$store.state.user.userInfo.nickname : '';
    },
    icon: function () {
      return this.$store.state.user ? (this.$store.state.user.userInfo.icon != '-1' ? '/file/' + this.$store.state.user.userInfo.icon : this.defaultHead) : this.defaultHead;
    },
  },
  methods: {
    logout () {
      let self = this;
      self.$store.dispatch("signOut").then(() => {
        self.$bus.emit('isLogin', false);
        self.$router.replace('/Index');
      });
    },
    userInfo () {
      let self = this;
      self.$router.replace('/Mgt/UserInfo')
    },
    passwordClose () {
      let self = this;
      self.isChangePassword = false;
      self.password.old = "";
      self.password.new = "";
      self.password.repeat = "";
    },
    changePassword () {
      let self = this;
      self.$api.auth.changePass({
        oldPass: self.password.old,
        newPass: self.password.new
      }).then(data => {
        self.$message.success('修改成功');
        self.isChangePassword = false;
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.user-info {
  height: 50px;
  display: flex;
  flex-direction: row;
  margin: 5px 10px;
  align-items: center;
  cursor: pointer;

  &:hover {
    img {
      box-shadow: 0 0 4px rgba(255, 255, 255, 0.2);
    }

    .user-name {
      span {
        color: #fff;
        text-shadow: 0 0 4px rgba(255, 255, 255, 0.1);

        &:after {
          border-top-color: rgba(255, 255, 255, 0.6);
        }
      }
    }

    ul {
      opacity: 1;
      pointer-events: auto;
      transform: translateY(0);
    }
  }

  img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #fff;
    margin-right: 10px;
  }

  .user-name {
    position: relative;
    height: inherit;

    span {
      height: inherit;
      font-size: 16px;
      display: flex;
      align-items: center;
      color: rgba(255, 255, 255, 0.9);
      flex-direction: row;

      &:after {
        content: "";
        border-top: 5px solid rgba(255, 255, 255, 0.4);
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        margin-left: 8px;
      }
    }
  }

  ul {
    width: 100%;
    min-width: 120px;
    max-width: 180px;
    position: absolute;
    top: 50px;
    right: 0;
    z-index: 995;
    padding: 4px;
    background: rgb(27, 38, 59);
    border-radius: 6px;
    list-style: none;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
    opacity: 0;
    pointer-events: none;
    transform: translateY(-20px);
    transition: all 0.2s linear;

    li {
      width: 100%;
      height: 32px;
      float: left;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      border-radius: 4px;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
        color: #fff;
      }

      i {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: inherit;
        opacity: 0.5;
        margin-right: 4px;
        font-style: normal;
        font-size: 16px;
      }
    }
  }
}
</style>