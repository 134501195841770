<template>
  <div class="header">
    <div class="header-left" @click="jumpMgt()">
      <img src="~assets/images/logo.png" alt="logo">
      <span>蓝色星球可视化数据大屏平台</span>
    </div>
    <div class="header-center">

    </div>
    <div class="header-right">
      <user-status></user-status>
      <no-trace v-if="isAdmin"></no-trace>
      <management v-if="isAdmin"></management>
    </div>
  </div>
</template>

<script>
import UserStatus from './childComps/User';
import NoTrace from './childComps/NoTrace.vue';
import Management from './childComps/Management.vue';
import _ from "underscore";

export default {
  name: 'Header',
  components: {
    UserStatus,
    NoTrace,
    Management,
  },
  computed: {
    isAdmin: function () {
      return this.$store.state.user ? (this.$store.state.user.roleInfos[0].id == '-2' || this.$store.state.user.roleInfos[0].id == '0' || this.$store.state.user.roleInfos[0].id == 1) : false
    }
  },
  methods: {
    jumpMgt () {
      let self = this;
      let jumpIndexs = ['/Mgt/SpaceMgt', '/Mgt/ProjectsMgt', '/Mgt/Market']
      if (_.contains(jumpIndexs, self.$route.path)) {
        self.$router.push('/Index');
      } else {
        let spOrPrj = self.$store.state.Global.spOrPrj;
        if (!spOrPrj) {
          self.$api.sp.getCurrentModuleType().then((data) => {
            if ("space" === data)
              self.$router.push('/Mgt/SpaceMgt');
            else
              self.$router.push('/Mgt');
          })
        } else {
          if ("space" === spOrPrj)
            self.$router.push('/Mgt/SpaceMgt');
          else
            self.$router.push('/Mgt');
        }

      }

    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 0 10px;

  .header-left,
  .header-center,
  .header-right {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .header-left {
    img {
      height: 42px;
      margin-right: 10px;
    }

    span {
      display: flex;
      align-items: center;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.9);
      font-size: 28px;
    }
  }

  .header-center {
    flex: 1;
    padding: 0 16px;
  }
}
</style>