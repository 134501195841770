<!--无痕模式-->
<template>
  <el-tooltip class="box-item" effect="dark" :content="contentText()" placement="bottom">
    <div class="no-trace" @click="noTraceToggle()" :class="{ 'active': isUse }">
      <img src="~assets/images/icons/no-trace.svg" alt="">
    </div>
  </el-tooltip>
</template>
<script>
export default {
  name: 'no-trace',
  components: {},
  data () {
    return {
      isUse: this.$store.state.trace,
    }
  },
  methods: {
    contentText () {
      let text = this.isUse ? '正在使用无痕模式' : '无痕模式'
      return text
    },
    noTraceToggle () {
      this.isUse = !this.isUse;
      this.$api.auth.changeTrace({flag: this.isUse}).then(() => {
        this.$message.success(this.isUse ? '开启无痕模式' : '关闭无痕模式');
      })
    }
  },
  watch: {
    "$store.state.trace":{
      handler:function(newVal,oldVal){
        this.isUse = newVal;
      }
    }
  }
};
</script>
<style lang='scss' scoped>
.no-trace {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border-radius: 8px;
  cursor: pointer;
  transition: all .1s linear;

  &:hover {
    background: rgba(255, 255, 255, .1);

    img {
      opacity: .8;
    }
  }

  &.active {
    background: rgba(0, 130, 255, .8);

    img {
      opacity: 1;
    }
  }

  img {
    width: 24px;
    height: 24px;
    pointer-events: none;
    opacity: .6;
    transition: all .1s linear;
  }
}
</style>