<!--管理界面-->
<template>
  <el-tooltip class="box-item" effect="dark" :content="contentText()" placement="bottom">
    <div class="no-trace " @click="jumpManagement()">
      <img src="~assets/images/icons/admin.svg" alt="">
    </div>
  </el-tooltip>
</template>
<script>
import { useRouter } from "vue-router";

export default {
  name: 'Management',
  components: {},
  setup () {
    const router = useRouter();
    const contentText = () => {
      let text = '管理界面'
      return text
    };

    const jumpManagement = () => {
      router.push('/Manage/UserManagement');
    };

    return {
      contentText,
      jumpManagement
    }
  },
};
</script>
<style lang='scss' scoped>
.no-trace {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border-radius: 8px;
  cursor: pointer;
  transition: all .1s linear;

  &:hover {
    background: rgba(255, 255, 255, .1);

    img {
      opacity: .8;
    }
  }

  &.active {
    background: rgba(0, 130, 255, .8);

    img {
      opacity: 1;
    }
  }

  img {
    width: 24px;
    height: 24px;
    pointer-events: none;
    opacity: .6;
    transition: all .1s linear;
  }
}
</style>